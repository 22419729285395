import * as authApi from './moudules/authApi';
import * as slideshow from './moudules/slideshow';
import * as talent from './moudules/talent';
import * as policy from './moudules/policy';
import * as article from './moudules/article';
import * as street from './moudules/street';
import * as enterprise from './moudules/enterprise';
import * as ry from './moudules/ry';
import * as message from './moudules/message';
import * as position from './moudules/position';
import * as merchantApi from './moudules/merchantApi';
import * as basicDataApi from './moudules/basicDataApi';
import * as programApi from './moudules/programApi';


// 默认导出
export default {
    authApi,
    slideshow,
    talent,
    policy,
    article,
    street,
    enterprise,
    ry,
    message,
    position,
    merchantApi,
    basicDataApi,
    programApi,
}
