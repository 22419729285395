import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js"
// import locale from 'element-plus/lib/locale/lang/zh-cn'
import Vant from 'vant';
import 'vant/lib/index.css';
import filters from "./utils/filters"
import store from "./store2"
import api from './api';




const app = createApp(App)

app.config.globalProperties.$filters = filters;
// .use(locale) use(ElementPlus,{locale})
app.use(ElementPlus,{locale: zhCn,}).use(store).use(api).use(router).use(Vant).mount('#app')
