import axios from "../axios";

// 登录
export const login = (params) => {
    let response = axios('post',`/api/auth/login`,params);
    return response;
};

// 获取验证码
export const verify_code = (params) => {
    return axios('post', '/api/auth/send/verify_code', params);
}

// 判断 验证码 是否正确
export const check_verify_code = (params) => {
    return axios('get', '/api/auth/check_verify_code', params);
}

// 重置密码
export const reset_password = (params) => {
    return axios('post', '/api/auth/reset_password', params);
}

// 用户注册
export const register_user = (params) => {
    return axios('post', '/api/auth/register_user', params);
}

// 验证码登陆
export const verify_login = (params) => {
    return axios('post', '/api/auth/verify_login', params);
}

// 所属企业员工账号列表(个人账号)
export const get_staff_list = (params) => {
    return axios('get', '/api/user/get_staff_list', params);
}

// 所属企业账号列表(本企业账号)
export const get_employees_list = (params) => {
    return axios('get', '/api/user/get_employees_list', params);
}

// 操作绑定员工(通过，驳回，删除，离职)
export const audit_staff = (params) => {
    return axios('post', '/api/user/audit_staff', params);
}

// 企业邀请同事(企业账号)
export const invite_employee = (params) => {
    return axios('post', '/api/user/invite_employee', params);
}

// 编辑用户信息
export const editUserInfo = (params) => {
    return axios('post', '/api/user/editUserInfo', params);
}

// 企业离职用户
export const dismiss_employee = (params) => {
    return axios('post', '/api/user/dismiss_employee', params);
}

// 获取账号信息
export const getqueryUserInfo = (params) => {
    return axios('get', '/api/user/queryUserInfo', params)
}
 