import { createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import localStorageUtil from '../utils/localStorageUtil'
import common from '@/utils/common'

const routes = [
	{
		path: '/',
		redirect: '/home'
	},
	{
		path: '/home',
		name: 'home',
		component: () => import('../views/home.vue'),
		meta: {
		  title: '首页',
		}
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('../views/login/login.vue'),
		meta: {
		  title: '登录',
		}
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/login/register.vue'),
		meta: {
		  title: '注册',
		}
	},
	{
		path: '/article',
		name: 'article',
		component: () => import('../views/article/article.vue'),
		meta: {
		  title: '资讯中心',
		}
	},
	{
		path: '/dossier',
		name: 'dossier',
		component: () => import('../views/dossier/dossier.vue'),
		meta: {
		  title: '档案',
		}
	},
  	{
		path: '/policy',
		name: 'policy',
		redirect: '/policy/talentPolicy',
		component: () => import('../views/policy/index.vue'),
		meta: {
		  title: '政策申报',
		},
		children: [
			{
				path: 'talentPolicy',
				name: 'talentPolicy',
				component: () => import('../views/policy/policy.vue'),
				meta: {
					title: '政策申报',
				},
			},
			{
				path: 'policyInfo',
				name: 'policyInfo',
				component: () => import('../views/policy/policyInfo.vue'),
				meta: {
					title: '政策申报',
				},
			},
		]
	},
	{
		path: '/policyApply',
		name: 'policyApply',
		component: () => import('../views/policy/policyApply.vue'),
		meta: {
			title: '政策申报填写',
		},
	},
	{
		path: '/applyDetail',
		name: 'applyDetail',
		component: () => import('../views/entManage/applyDetail.vue'),
		meta: {
			title: '申报详情',
		},
	},
	{
		path: '/identify',
		name: 'identify',
		redirect: '/identify/identifyList',
		component: () => import('../views/identify/index.vue'),
		meta: {
		  title: '人才认定',
		},
		children: [
			{
				path: 'identifyList',
				name: 'identifyList',
				component: () => import('../views/identify/identify.vue'),
				meta: {
					title: '人才认定',
			  	},
			},
			{
				path: 'identifyInfo',
				name: 'identifyInfo',
				component: () => import('../views/identify/identifyInfo.vue'),
				meta: {
					title: '人才认定',
			  	},
			},
		]
	},
	{
		path: '/opportunity',
		name: 'opportunity',
		redirect: '/opportunity/list',
		component: () => import('../views/opportunity/index.vue'),
		meta: {
		  	title: '人才机遇',
		},
		children: [
			{
				path: 'list',
				name: 'list',
				component: () => import('../views/opportunity/opportunity.vue'),
				meta: {
					title: '人才机遇',
			  	},
			},
			{
				path: 'jobInfo',
				name: 'jobInfo',
				component: () => import('../views/jobInfo/jobInfo.vue'),
				meta: {
					title: '人才机遇',
				},
			}
		]
	},
	{
		path: '/information',
		name: 'information',
		component: () => import('../views/information/information.vue'),
		meta: {
		  title: '资讯中心',
		},
	},
	{
		path: '/entManage',
		name: 'entManage',
		component: () => import('../views/entManage/entManage.vue'),
		meta: {
		  title: '企业管理',
		},
	},
	{
		path: '/policyOverview',
		name: 'policyOverview',
		component: () => import('../views/policyOverview/policyOverview.vue'),
		meta: {
			title: '政策一览',
		},
	},
	{
		path: '/policyOverviewInfo',
		name: 'policyOverviewInfo',
		component: () => import('../views/policyOverview/policyOverviewInfo.vue'),
		meta: {
			title: '政策一览详情',
		},
	},
	{
		path: '/policyContent',
		name: 'policyContent',
		component: () => import('../views/policyOverview/policyContent.vue'),
		meta: {
			title: '政策一览详情',
		},
	},
	{
		path: '/serviceHome',
		name: 'serviceHome',
		component: () => import('../views/service/serviceHome.vue'),
		meta: {
			title: '人才服务',
		},
	},
	{
		path: '/health',
		name: 'health',
		component: () => import('../views/service/health.vue'),
		meta: {
			title: '人才服务',
		},
	},
	{
		path: '/fprCard',
		name: 'fprCard',
		component: () => import('../views/service/fprCard.vue'),
		meta: {
			title: '人才服务',
		},
	},
	{
		path: '/storage',
		name: 'storage',
		component: () => import('../views/service/storage.vue'),
		meta: {
			title: '人才服务',
		},
	},
	
	{
		path: '/account',
		name: 'account',
		component: () => import('../views/account/account.vue'),
		meta: {
		  title: '账号管理',
		},
	},
]

const router = createRouter({
  history: createWebHashHistory(),
//   history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
    to.meta.title ? document.title = to.meta.title+'--青睐人才-青羊区智慧人才服务平台': document.title ='青睐人才-青羊区智慧人才服务平台'
    if(to.meta.title=='首页'){
        
        document.title ='青睐人才-青羊区智慧人才服务平台'
    }
    next()
})
router.beforeEach((to, from, next) => {
    if (to.meta.requireAuth) {

        const token = localStorageUtil.get("token");
        // console.log(to.fullPath);
        if (token) {
            next();
        }
        else {
            next({
                path: '/login',
                query: { redirect: to.fullPath }  // 将跳转的路由path作为参数，登录成功后跳转到该路由
            })
        }

    }
    else {
        next();
    }
});

export default router
