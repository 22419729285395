let common = {};


/*中国手机号验证*/
common.checkCellPhoneCN = (phoneNumber) => {
    let reg = /^1\d{10}$/;
    return reg.test(phoneNumber);
};

/*邮箱格式验证*/
common.checkEmailFormat = (emailAddr) => {
    let reg = /^([a-zA-Z0-9]+(_|-|.)?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
    return reg.test(emailAddr);
};

/*只包含英文字母验证*/
common.check_En_letter_only = (input_value) => {
    let reg = /^[A-Za-z]+$/;
    return reg.test(input_value);
};
/*只包含数字、下划线和英文验证*/
common.check_En_letter_num_only = (input_value) => {
    let reg = /^[A-Za-z0-9_.]+$/;
    return reg.test(input_value);
};

common.isEmpty = (value) => {
    if(value === undefined || value === null || value === ''){
        return true;
    }

    return false;
}

common.isEmptyArray = (value) => {
    if(value === undefined || value === null || value.length == 0){
        return true;
    }

    return false;
}

common.ArrayContains = (arr,value) =>{
    if(arr === undefined || arr === null){
        return false;
    }

    if(value === undefined || value === null || value === ''){
        return false;
    }

    return arr.indexOf(value) >= 0;
}

common.formatDate = (dateStr, fmt) =>{

    if(dateStr == undefined || dateStr == null || dateStr == ''){
        return '';
    }

    let date = new Date(dateStr);

    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    }
    let o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
    };
    for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
            let str = o[k] + '';
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
        }
    }
    return fmt;
}

common.timeFormat2 = (inputTime) =>{

    if(inputTime == null)
    {
        return "";
    }

    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + '-' + m + '-' + d;
};

common.timeFormat3 = (inputTime) =>{

    if(inputTime == null)
    {
        return "";
    }

    var date = new Date(inputTime);
    var y = date.getFullYear();
    var m = date.getMonth() + 1;
    m = m < 10 ? ('0' + m) : m;
    var d = date.getDate();
    d = d < 10 ? ('0' + d) : d;
    var h = date.getHours();
    h = h < 10 ? ('0' + h) : h;
    var minute = date.getMinutes();
    var second = date.getSeconds();
    minute = minute < 10 ? ('0' + minute) : minute;
    second = second < 10 ? ('0' + second) : second;
    return y + m + d;
};

common.sleep = (ms) => {
    return new Promise(resolve =>
        setTimeout(resolve, ms)
    )
}

common.arrayIndexOfItem = (array,item,pro) =>{//设置text的时候判断

    for(var i=0;i<array.length;i++){
        var model = array[i];

        if(model[pro] == item[pro])
        {
            return i;
        }
    }

    return -1;
}

common.generateUUID =() => {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
};

common.getBillingYears = (value) => {
    if(common.isEmpty(value)){
        return "";
    }
    var str = value.toString();

    return str.substring(0,4) + "-" + str.substring(4,6) + "-01";
}


common.transformFileTypeBySuffix = (filename) => {

    var arr = filename.split('.');

    var suffix = arr[arr.length - 1];

    if(suffix== "doc" || suffix== "docx" || suffix== "DOC" || suffix== "DOCX"){
        return "iconword";
    }

    //jpeg
    if(suffix== "jpg" || suffix== "JPG"|| suffix== "JPEG"|| suffix== "jpeg"){
        return "iconjpg";
    }
    if(suffix== "png" || suffix== "PNG"){
        return "iconpng";
    }
    if(suffix== "gif" || suffix== "GIF"){
        return "icongif";
    }
    //plain
    if(suffix== "txt" || suffix== "TXT"){
        return "icontxt";
    }

    //"application/"
    if(suffix == "xls" || suffix == "xlsx" || suffix== "XLS" || suffix== "XLSX"){
        return "iconexcel";
    }


    if(suffix == "ppt" || suffix == "pptx" || suffix== "PPT" || suffix== "PPTX"){
        return "iconppt";
    }

    if(suffix== "pdf" || suffix== "PDF"){
        return "iconadobepdf";
    }

    if(suffix== "html" || suffix== "htm" || suffix== "xhtml" || suffix== "HTML" || suffix== "HTM" || suffix== "XHTML"){
        return "iconwww";
    }

    if(suffix== "wps" || suffix== "WPS"){
        return "icontxt";
    }

    if(suffix== "xml" || suffix== "XML"){
        return "icontxt";
    }

    if(suffix== "eml" || suffix== "EML"){
        return "icontxt";
    }

    if(suffix== "wml" || suffix== "WML"){
        return "icontxt";
    }

    if(suffix== "msg" || suffix== "MSG"){
        return "icontxt";
    }

    if(suffix== "mht" || suffix== "MHT"){
        return "iconwww";
    }

    if(suffix== "rtf" || suffix== "RTF"){
        return "icontxt";
    }

    if(suffix == "zip" || suffix == "ZIP"){
        return "iconzip"
    }

    if(filename==suffix){
        suffix = "icontxt";
    }
    return suffix;
};

function padLeftZero(str) {
    return ('00' + str).substr(str.length);
}

common.getUrlDomain = () =>{
    var url = window.location.href;
    if(url != null || url != ''){
        var domain = url.split('/')[2];

        return domain.replace(".sangucloud.com","");
    }

    return "contest";
}

common.saveAs = (blob,downName) => {
    var fileURL = window.URL.createObjectURL(blob);
    var fileLink = document.createElement('a');
    
    fileLink.href = fileURL;
    fileLink.setAttribute('download', downName);
    document.body.appendChild(fileLink);
    
    fileLink.click();

    document.body.removeChild(fileLink);
}


export default common;
