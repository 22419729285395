import axios from "../axios";

// 获取我的流程
export const get_my_process = (params) => {
    return axios('get', '/api/ry/get_my_process_pc', params);
}

// 取消申请
export const stopProcess = (params) => {
    return axios('post', '/api/ry/stopProcess', params);
}

// 企业审核用户提交的认定申请（通过和驳回）
export const audit_flow_apply = (params) => {
    return axios('post', '/api/ry/audit_flow_apply', params);
}

// 判断是否能重新申请流程，判断是否该用户有一条进行中的该认定的申请
export const reapply_judge = (params) => {
    return axios('get', '/api/ry/reapply_judge', params);
}