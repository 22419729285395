let localStorageUtil = {};

/*设置-带过期时间的localStorage*/

localStorageUtil.set = (key, data, expire) => {

    let name = "AI." + key;

    const time = new Date().getTime() + expire * 60 * 60 * 1000; //以小时为单位
    // const time = new Date().getTime() + expire * 60 * 1000; //以分钟为单位
    const obj = {
        data,
        time
    };
    localStorage.setItem(name, JSON.stringify(obj));
};

/*获取-带过期时间的localStorage-isJSONorNot*/
function isJSONorNot(str) {
    if (typeof str === 'string' && '' !== str) {
        try {
            JSON.parse(str);
            return true;
        } catch (e) {
            return false;
        }
    }
}

localStorageUtil.remove = key =>{
    let name = "AI." + key;

    localStorage.removeItem(name)
}


// 获取localStorage
localStorageUtil.get = key => {

    let name = "AI." + key;

    const storage = localStorage.getItem(name);
    const time = new Date().getTime();
    let result = null;
    if (storage && isJSONorNot(storage)) {
        const obj = JSON.parse(storage);
        if (!obj.time || time < obj.time) {
            result = obj.data;
        } else {
            localStorage.removeItem(name);
        }
    }
    return result;
};

export default localStorageUtil;
