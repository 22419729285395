import axios from 'axios'
import config from './config';
import localStorageUtil from '../utils/localStorageUtil'
import { ElMessage,ElMessageBox,ElLoading } from 'element-plus'
import  defaultStore from '../store2/moudules/defaultStore'

const loadOptions = {
    background: 'rgba(225,225,225,0.2)',
    lock: true,
    target: "body",
    fullscreen: true,
};

let loadingInstance;
let loadingLock = false;

const instance = axios.create(config);
//------------------- 一、请求拦截器 忽略
instance.interceptors.request.use(function (config) {

    let isLoading = true;
    let params = null;
    if(config.params){
        params = config.params;
    }else{
        params = config.data;
    }
    if(params != null && params.isLoading == 0){
        isLoading = false;
    }else{
        isLoading = true;
    }

    config.headers = {
        'Content-Type':'application/json;charset=utf-8', //配置请求头
    }
    //  注意使用token的时候需要引入cookie方法或者用本地localStorage等方法，推荐js-cookie
    const token = localStorageUtil.get('token');//这里取token之前，你肯定需要先拿到token,存一下
    const userId = localStorageUtil.get('userId');//这里取token之前，你肯定需要先拿到token,存一下
    const orgCode = localStorageUtil.get('orgCode');//这里取token之前，你肯定需要先拿到token,存一下
    if(token){
        // config.params = {'Authorization':token} //如果要求携带在参数中
        config.headers.Authorization= token; //如果要求携带在请求头中
        config.headers.SG_AUTH_ID= userId; //如果要求携带在请求头中
        config.headers.SG_ORG_CODE= orgCode; //如果要求携带在请求头中
    }

    if(isLoading && !loadingLock){
        loadingLock = true;
        // loadOptions.target = commonStore.state.loadTarget;
        loadingInstance = ElLoading.service(loadOptions);
    }

    return config;
}, function (error) {
    // 对请求错误做些什么

    if(loadingInstance){
        loadingInstance.close();
        loadingLock = false;
    }

    ElMessage.error(error.message);
    return Promise.reject(error);
});

//----------------- 二、响应拦截器 忽略
instance.interceptors.response.use(function (response) {

    if(loadingInstance){
        loadingInstance.close();
        loadingLock = false;
    }

    let userId = localStorageUtil.get("userId");
    // if(userId && userId != null && response.config.url != '/msg/api/msg/timely/get_no_count_msg_count'){
    //     defaultStore.commit("loadMessageCount");
    // }

    //需要判断是否200
    if(response.data.code === "200"){
        return response.data;
    }
    else if(response.data.code == "50008" || response.data.code == "50012"){
        ElMessageBox.alert('登录异常，token不合法，请重新登录！','登录异常',{
            confirmButtonText: '确定',
            callback: action => {
                localStorage.clear();
                window.location.href="#/login"
                // return Promise.reject();

            }
        });
        return response.data;
    }
    else  if(response.data.code === "-1095"){
        return response.data;
    }
    else if(response.data.code === "50001"){
        ElMessage.error("越权");
        return Promise.reject();
    }
    else if(response.data.code === "50000"){
        ElMessageBox.alert('登录异常，缺少必要参数，请重新登录！','登录异常',{
            confirmButtonText: '确定',
            callback: action => {
                window.location.href="#/login"
                return Promise.reject();

            }
        });
    }
    else if(response.data.code === "50010" ){ //token失效
        // window.location.href="/login"

        ElMessageBox.alert('登录已过期，请重新登录！','登录过期',{
            confirmButtonText: '确定',
            callback: action => {
                defaultStore.commit("clearLocalStorage");
                window.location.href="#/login"
            }
        })

    }
    else{
        if(response.data.code.indexOf('_ERROR_') >= 0){
            return response.data;
        }

        ElMessage.error(response.data.msg);
        return Promise.reject();
    }
}, function (error) {

    if(loadingInstance){
        loadingInstance.close();
        loadingLock = false;
    }

    if(error.response == undefined){
        let message = error.message;
        ElMessage.error(message);

        if(error.stack){
            console.log(error.stack)
        }
    }
    else{
        let message = error.response.data.error;
        let errors = JSON.stringify(error.response.data.errors);

        ElMessage.error(message + ",errors:" + errors);
    }




    // 对响应错误做点什么
    return Promise.reject(error);
});

/**
 * 使用es6的export default导出了一个函数，导出的函数代替axios去帮我们请求数据，
 * 函数的参数及返回值如下：
 * @param {String} method  请求的方法：get、post、delete、put
 * @param {String} url     请求的url:
 * @param {Object} data    请求的参数
 * @returns {Promise}     返回一个promise对象，其实就相当于axios请求数据的返回值
 */
export default function (method, url, data = null) {
    method = method.toLowerCase();
    if (method == 'post') {
        return instance.post(url, data)
    } else if (method == 'get') {
        return instance.get(url, { params: data })
    } else if (method == 'delete') {
        const config = {
            method: 'delete',
            url:url
        }
        if(data) config.data = data

        return instance(config)
    }else if(method == 'put'){
        return instance.put(url,data)
    }else{
        console.error('未知的method'+method)
        return false
    }
}
