import axios from "../axios";

// 获取人才认定列表
export const get_talent_list = (params) => {
    return axios('get', '/api/talent/get_talent_list', params);
}

// 获取人才认定详情
export const get_talent_detail = (params) => {
    return axios('get', '/api/talent/get_talent_detail', params);
}

// 跳转认定详情页判断认定是否删除
export const judge_talent_exist = (params) => {
    return axios('get', '/api/talent/judge_talent_exist', params);
}
// 跳转认定详情页判断政策是否删除
export const judge_policy_exist = (params) => {
    return axios('get', '/api/policy/judge_policy_exist', params);
}