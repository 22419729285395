import axios from "../axios";

// 职位需求列表
export const get_position_list = (params) => {
    return axios('get', '/api/position/get_position_list', params);
}
// 新增需求
export const create_position = (params) => {
    return axios('post', '/api/position/create_position', params);
}
// 需求详情
export const get_position_info = (params) => {
    return axios('get', '/api/position/get_position_info', params);
}
// 需求编辑
export const edit_position_info = (params) => {
    return axios('post', '/api/position/edit_position_info', params);
}
// 发布/下线需求
export const audit_position = (params) => {
    return axios('post', '/api/position/audit_position', params);
}