import axios from "../axios";

export const getAreaNameCodeByAreaCode = (params) => {
    return axios('get','/api/city/getAreaNameCodeByAreaCode',params);
};

export const getAreaListByParentCode = (params) => {
    return axios('get','/api/city/getAreaListByParentCode',params);
};




