import axios from "../axios";

// 政策详情
export const getPolicyDetailInfo = (params) => {
    return axios('get', '/api/policy/getPolicyDetailInfo', params);
}

// 政策区域和待遇分类
export const getPolicyDatalInfo = (params) => {
    return axios('get', '/api/policy/getPolicyDatalInfo', params);
}
// 政策列表
export const getPolicyInfoListV2 = (params) => {
    return axios('get', '/api/policy/getPolicyInfoListV2', params);
}

// 政策列表
export const getPolicyInfoList = (params) => {
    return axios('get', '/api/policy/getPolicyInfoList', params);
}

// 全部政策列表
export const getPolicyList = (params) => {
    return axios('get', '/api/policy/getPolicyList', params);
}

// 政策一览列表
export const getPolicyViewList = (params) => {
    return axios('get', '/api/policy/getPolicyViewListV2', params);
}
// 政策一览详情
export const getPolicyViewInfo = (params) => {
    return axios('get', '/api/policy/getPolicyViewInfo', params);
}
// 医疗列表
export const getMedicalList = (params) => {
    return axios('get', '/api/policy/getMedicalList', params);
}
